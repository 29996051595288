import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import EventRepeat from "@mui/icons-material/EventRepeat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Button as MUIButton,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import ConfirmationDialog from "components/global/confirmationDialog";
import CorporateIcon from "components/icons/corporate";
import NetworkIcon from "components/icons/network";
import {
  CheckGrey,
  Description,
  ExclamationCircle,
  FileAdd,
  Folder,
  Location,
  Patient,
  Person,
  Provider2,
  Thermometer,
  X,
} from "components/oldDesignAssets/icons";
import { useSnackBar } from "contexts/snackBarContext";
import { addMinutes, format, parse, roundToNearestMinutes } from "date-fns";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { createAvixoUser, getAddOnItems, getSymptoms } from "services/dispatcherService";
import { getRecommendedProviders } from "services/ubfService";
import LoadingSection from "../../../components/loadingSection";
import Request from "../../../components/universalModal/pages/request";
import { createRequest as createErosterRequest } from "../../../services/eRosterService";
import {
  createRequest as createDispatcherRequest,
  createRequestFromDraft,
  editDraft,
  getEstimatedPrice,
} from "../../../services/requestService";
import { getPatientsFromUSMS, getUser } from "../../../services/userService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getAvailablePaymentMethods } from "../../../utils/getAvailablePaymentMethods";
import { availableServices, providersAvailable } from "../../../utils/globalConfig";
import {
  containsAll,
  getDefaultEndTime,
  getFormattedAddress,
  getInterval,
  getPaymentIcon,
  isValidDate,
} from "../../../utils/globalUtils";
import Price from "../../global/price";
import HospitalIcon from "../../icons/hospitalIcon";
import Alert from "../alert";
import AutoComplete from "../autoComplete";
import Avatar from "../avatar";
import Button from "../button";
import Checkbox from "../checkbox";
import Footer from "../footer";
import Header from "../header";
import {
  BlueShield,
  BluedotDash,
  Building,
  Heartbeat,
  LocationList,
  NotificationDisable,
  NotificationEnable,
  PatientNotes,
  SwapVertical,
} from "../icons";
import Input from "../input";
import InputButton from "../inputButton";
import NotificationModal from "../notificationModal";
import Schedule from "../schedule";
import Select from "../select";
import StatusTag from "../statusTag";
import Tabs from "../tabs";
import Tag from "../tag";
import TagInfo from "../tagInfo";
import Tooltip from "../tooltip";
import Notification from "./Notification";
import AddonList from "./addonList";
import AddressList from "./addressList";
import CaseList from "./caseList";
import ClinicList from "./clinicList";
import CreateUser from "./createUser";
import DestinationList from "./destinationList";
import MedDeliveryForm from "./medDeliveryForm";
import PaymentMethod from "./paymentMethod";
import ProviderAssignment from "./providerAssignment";
import RecurringAppointment from "./recurringAppointment";
import SymptomList from "./symptomList";
import PaymentHistory from "./paymentHistory";

const personIcon = <Person />;
const locationIcon = <Location />;
const caseIcon = <Folder />;
const symptomIcon = <Thermometer />;
const patientIcon = <Patient />;
const notesIcon = <Description />;
const patientNotes = <PatientNotes />;
const heartbeatIcon = <Heartbeat />;
const locationList = <LocationList />;
const bluedotIcon = <BluedotDash />;
const fileAddIcon = <FileAdd />;

const origins = {
  Website: {
    icon: <NetworkIcon />,
  },
  "In-bound call": {
    icon: <PhoneIcon sx={{ height: "16px", width: "16px" }} />,
  },
  "Out-bound call": {
    icon: <PhoneIcon sx={{ height: "16px", width: "16px" }} />,
  },
  WhatsApp: {
    icon: <WhatsAppIcon sx={{ height: "16px", width: "16px" }} />,
  },
  Corporate: {
    icon: <CorporateIcon />,
  },
};

export default function CreateRequest() {
  const {
    destinationItems,
    hideModal,
    serviceItems,
    symptoms,
    unsavedChanges: request,
    config,
  } = requestStore.useState(s => s);
  const { country, alpha2, language } = store.useState(s => ({
    country: s.country,
    alpha2: s.country.alpha2,
    language: s.language,
  }));

  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);

  const location = useLocation();
  const snackBar = useSnackBar();

  const errorRef = useRef();
  errorRef.current = error;

  const filteredAvailableServices = useMemo(
    () =>
      request?.providers
        ? availableServices.filter(s =>
            Object.keys(request?.providers[0]?.primary)
              .filter(p => request?.providers[0]?.primary[p])
              .includes(s.toLowerCase()),
          )
        : availableServices,
    [request?.providers],
  );

  const activeTabIndex = useMemo(
    () => filteredAvailableServices.findIndex(s => s === request.type),
    [filteredAvailableServices, request.type],
  );

  const isDraft = request.status === "draft";
  const { free, selectedPaymentMethod: paymentMethod, payments } = request.billing;
  const freePaymentMethod = { preferredMethod: undefined, free: true, note: "" };
  const headerText = request.isRebook ? "Rebook Request" : "Create Request";
  const buttonText = request.isRebook ? "Rebook" : "Book";
  const payer = request.owner || request.user;
  const patient = request.user;
  const symptomRecord = request.records.find(r => r.type === "symptoms");
  const selectedSymptoms = symptomRecord?.data?.symptoms?.map(s => {
    const symptom = typeof s === "string" ? symptoms.find(x => x.title === s) : s;
    return symptom?.title ?? s;
  });
  const clinicalNote = request.records.find(r => r.type === "clinicalNotes")?.data.clinicalNotes[0].message;
  const jarvisNote = request.records.find(r => r.type === "jarvisNotes")?.data.jarvisNotes[0].message;
  const isDelivery = request.type === "Delivery";
  const isAmbulanceDelivery = isDelivery && request.specialty === "Ambulance";
  const isMedDelivery = isDelivery && request.specialty === "Rider";
  const isClinic = request.type === "Clinic";
  const destinationItem = request.items.find(item => item.item?.tags?.includes("destination"))?.item;
  const { requestedAddress, destinationAddress, isPickup } = request.schedule;
  const programTitle = request.case.program?.title || "";
  const caseBusinessTag = request.case.business?.tag || "";
  const caseId = (request.case.id && `C-${request.case.id}`) || "(New)";
  const isFormDirty = Object.keys(request.isDirty).length > 0;

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await getAddOnItems({ country: alpha2 });
        const addOnItems = data?.items;
        if (!addOnItems) throw new Error("Failed to retrieve Items");

        requestStore.update(s => {
          s.destinationItems = addOnItems.filter(i => i.tags.includes("destination"));
          s.addonItems = addOnItems.filter(i => !i.tags.includes("destination"));
          if (
            s.unsavedChanges.status === "draft" &&
            s.unsavedChanges.type === "Delivery" &&
            s.unsavedChanges.specialty === "Ambulance"
          ) {
            const selectedDestinationItem = s.destinationItems.find(i => i.data.placeType === "hospital");
            s.unsavedChanges.items = [{ quantity: 1, item: selectedDestinationItem }];
          }
        });
      } catch (err) {
        snackBar.pop({ content: err, alertProps: { severity: "error" } });
      }
    };
    fetch();
  }, [alpha2, snackBar]);

  useEffect(() => {
    if (!request.type && !request.specialty) return;

    const fetchSymptoms = async () => {
      try {
        const result = await getSymptoms({
          alpha2,
          serviceType: request.type,
          serviceSpecialty: request.specialty,
        });

        requestStore.update(s => {
          s.symptoms = result.data.symptoms;
        });
      } catch (error) {
        snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
      }
    };

    fetchSymptoms();
  }, [alpha2, request.type, request.specialty, snackBar]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const defaultSpecialty = isAmbulanceDelivery ? "Ambulance" : "General Practitioner";

        const selectedItems = request.isIncludeVideo
          ? serviceItems.map(i => ({ quantity: i.quantity, item: { _id: i._id } }))
          : request.items
              .filter(i => !i.item?.tags.includes("service") && i.item?._id !== undefined)
              .map(i => ({ quantity: i.quantity, item: { _id: i.item._id } }));

        const { data } = await getEstimatedPrice({
          alpha2,
          userRef: (request.owner || request.user)._id,
          businessRef: request.business?._id,
          serviceType: request.type,
          serviceSpecialty: request.specialty || defaultSpecialty,
          caseType: request.case?.type,
          programRef: request.case?.program?._id,
          packageRef: request.case?.package?._id,
          date: request.schedule.start,
          selectedItems,
          code: request.billing?.code?.id,
        });
        requestStore.update(s => {
          s.unsavedChanges.billing.totals = data.pricing.totals;
          s.unsavedChanges.items = data.pricing.items;
        });
      } catch (e) {
        requestStore.update(s => {
          s.unsavedChanges.billing.totals = {};
        });
        setError({
          ...errorRef.current,
          alert: {
            text: e.data,
            type: "danger",
            action: {
              text: "Dismiss",
              onClick: () => {
                requestStore.update(s => {
                  s.unsavedChanges.schedule.start = undefined;
                });
                setError({ ...errorRef.current, alert: undefined });
              },
            },
          },
        });
      }
    };
    fetch();
  }, [
    request.items,
    request.schedule.start,
    request.billing?.code?.id,
    alpha2,
    request.owner,
    request.user,
    request.business,
    request.specialty,
    request.type,
    isAmbulanceDelivery,
    request.case?.type,
    request.case?.program?._id,
    request.case?.package?._id,
    serviceItems,
    request.isIncludeVideo,
  ]);

  const recommendedProvider = useRecommendedProvider({
    type: request.type,
    specialty: request.specialty,
    scheduleStart: request.schedule?.start,
    scheduleEnd: request.schedule?.end,
  });

  const showAddressList = () => showSubScreen(<AddressList />);
  const showClinicList = () => showSubScreen(<ClinicList />);

  const onClickClose = () => {
    if (isDraft && isFormDirty) {
      setShowUnsavedChangesDialog(true);
      return;
    }

    hideModal();
  };

  const handleVideoConsultPaymentError = () => {
    return {
      text: "Video Consult cannot be paid by cash",
      type: "danger",
      action: {
        text: "Change Payment",
        onClick: () => showSubScreen(<PaymentMethod />),
      },
    };
  };

  const validateRequest = () => {
    const err = {
      patient: !patient._id,
      payer: !payer?._id,
      schedule: !request.schedule.start,
      case: !request.case._id && !request.case.type,
      symptoms:
        request.type !== "Delivery" &&
        (!Array.isArray(symptomRecord?.data.symptoms) || !symptomRecord?.data.symptoms.length),
      patientCondition: isAmbulanceDelivery && !symptomRecord?.data.patientCondition,
      preferredMethod: !isMedDelivery && !request.billing.free && !request.billing.preferredMethod,
      requestedAddress: !isMedDelivery && !getFormattedAddress(requestedAddress),
      destinationAddress: isDelivery && !getFormattedAddress(destinationAddress),
      alert:
        request.type === "Video" && paymentMethod?.type === "cash" && !request.billing.noCharge
          ? handleVideoConsultPaymentError()
          : false,
      origin: !request.origin,
    };

    setError(err);

    return !(
      err.patient ||
      err.payer ||
      err.case ||
      err.symptoms ||
      err.patientCondition ||
      err.preferredMethod ||
      err.requestedAddress ||
      err.destinationAddress ||
      err.schedule ||
      err.origin
    );
  };

  const confirmServiceTypeChange = i => {
    const showConfirmation =
      getFormattedAddress(destinationAddress) ||
      request.items.length > 1 ||
      request.schedule.start ||
      (isClinic && requestedAddress);
    if (showConfirmation) {
      NotificationModal({
        callback: () => onChangeServiceType(i),
        action: "confirm",
      });
    } else onChangeServiceType(i);
  };

  const isSymptomCovered = (symptom, serviceType, serviceSpecialty) => {
    return symptom.serviceTypes?.includes(serviceType) && symptom.serviceSpecialties?.includes(serviceSpecialty);
  };

  const onChangeServiceType = serviceType => {
    const isDeliverySelected = serviceType === "Delivery";
    let defaultSpecialty = isDeliverySelected ? "Ambulance" : "General Practitioner";

    if (!isDeliverySelected && request.case?.package?.metadata?.specialty) {
      defaultSpecialty = request.case.package.metadata.specialty;
    }

    updateRequest({
      type: serviceType,
      isDirty: { ...request.isDirty, type: true },
      schedule: {
        ...request.schedule,
        requestedAddress: isClinic || serviceType === "Clinic" ? {} : request.schedule.requestedAddress,
        destinationAddress: isMedDelivery ? undefined : request.schedule.destinationAddress,
      },
      billing: { ...request.billing, noCharge: undefined },
      specialty: request.providers ? request.specialty : defaultSpecialty,
      placeRef: isClinic ? undefined : request.placeRef,
      isIncludeVideo: false,
    });

    error.alert = undefined;

    // Validate Symptoms
    if (serviceType !== "Delivery") {
      for (const symptom of symptomRecord?.data?.symptoms ?? []) {
        if (!isSymptomCovered(symptom, serviceType, request.specialty)) {
          error.alert = {
            text: `${serviceType} doesn't cover these symptoms`,
            type: "warning",
            action: {
              text: "Dismiss",
              onClick: () => setError({ ...error, alert: undefined }),
            },
          };
          break;
        }
      }
    }

    // Validate Payment Method
    if (serviceType === "Video") {
      if (paymentMethod?.type === "cash")
        error.alert = {
          text: "Video Consult cannot be paid by cash",
          type: "danger",
          action: {
            text: "Change Payment",
            onClick: () => showSubScreen(<PaymentMethod />),
          },
        };
    }
    setError({ ...error });
  };

  const onChangeSchedule = updatedFields => {
    let updatedSchedule = updatedFields;

    if (isMedDelivery) {
      updatedSchedule.end = getDefaultEndTime(request.specialty, request.type, updatedSchedule.start);
    }

    updateRequest({
      schedule: { ...request.schedule, ...updatedSchedule },
      isDirty: { ...request.isDirty, schedule: true },
    });
    setError({ ...error, schedule: undefined });
  };

  const updateRequest = updatedFields => {
    const updatedRequest = { ...request, ...updatedFields };
    requestStore.update(s => {
      s.unsavedChanges = updatedRequest;
    });
    return updatedRequest;
  };

  const onClickSaveChanges = async () => {
    setIsSavingDraft(true);

    try {
      const requestSchedule = extractSchedule();

      const changes = {
        type: request.type,
        specialty: request.specialty,
        ownerRef: !request.payerIsPatient ? payer?._id : undefined,
        userRef: patient._id,
        preferredPaymentMethod: paymentMethod?._id,
        free: request.billing.free,
        billingNote: request.billing.note,
        code: request.billing.code?.id,
        businessRef: request.business?._id,
        schedule: requestSchedule,
        placeRef: request.placeRef,

        symptoms: selectedSymptoms,
        patientCondition: symptomRecord?.data.patientCondition || undefined,
        notes: symptomRecord?.data.notes,
        clinicalNote,
        jarvisNote,

        selectedItems: request.items.filter(item => item.item.tags.includes("addons")),
      };

      await editDraft(request.draftRef, changes);

      snackBar.pop({ content: "Draft updated." });

      requestStore.update(s => {
        s.unsavedChanges.isDirty = {};
      });
    } catch (error) {
      snackBar.pop({ content: error.data || error.toString(), alertProps: { severity: "error" } });
    } finally {
      setIsSavingDraft(false);
    }
  };

  const extractSchedule = () => {
    const requestSchedule = cloneDeep(request.schedule);

    requestSchedule.start = requestSchedule.start || null;
    requestSchedule.end = requestSchedule.end || null;

    if (isAmbulanceDelivery) {
      if (isPickup) {
        requestSchedule.requestedAddress = cloneDeep(request.schedule.destinationAddress);
        requestSchedule.destinationAddress = cloneDeep(request.schedule.requestedAddress);
        requestSchedule.requestedAddress.subtitle = `From ${destinationItem.title}`;
      } else {
        requestSchedule.destinationAddress.subtitle = `To ${destinationItem.title}`;
      }
    }
    return requestSchedule;
  };

  const onConfirmSaveChanges = async () => {
    setShowUnsavedChangesDialog(false);
    onClickSaveChanges();
  };

  const onCancelSaveChanges = () => {
    hideModal();
  };

  const onCloseSaveChangesDialog = () => {
    setShowUnsavedChangesDialog(false);
  };

  const onClickBook = () => {
    if (validateRequest()) {
      const data = { numberOfAppointments: request.recurring?.numberOfAppointments };

      if (request.isSendBookingConfirmation) {
        NotificationModal({
          callback: onConfirmBook,
          data: {
            ...data,
            method: request.bookingConfirmationMethod,
            email: request.user.email.address,
            phoneNumber: payer.phoneNumbers[0],
          },
          action: "bookingWithConfirmation",
        });
      } else {
        NotificationModal({
          callback: onConfirmBook,
          data,
          action: "booking",
        });
      }
    }
  };

  const onConfirmBook = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const createRequestBase = location.pathname === "/e-roster" ? createErosterRequest : createDispatcherRequest;
      const createRequest = isDraft ? createRequestFromDraft : createRequestBase;
      const requestSchedule = extractSchedule();

      const selectedSymptoms = symptomRecord?.data?.symptoms?.map(s => {
        const symptom = typeof s === "string" ? symptoms.find(x => x.title === s) : s;
        return symptom?.title ?? s;
      });

      const createRequestParams = {
        type: request.type,
        schedule: requestSchedule,
        preferredPaymentMethod: paymentMethod?._id,
        businessRef: request.business?._id,
        symptoms: selectedSymptoms,
        free: request.billing.free,
        billingNote: request.billing.note,
        caseRef: request.case._id,
        caseType: !request.case._id ? request.case.type : undefined,
        programRef: request.case?.program?._id,
        packageRef: request.case?.package?._id,
        programCondition:
          !request.case._id && request.case.program?.condition ? request.case.program.condition : undefined,
        selectedItems: request.items,
        patientCondition: symptomRecord?.data.patientCondition || undefined,
        notes: symptomRecord?.data.notes,
        clinicalNote,
        jarvisNote,
        userRef: patient._id,
        ownerRef: !request.payerIsPatient ? payer?._id : undefined,
        alpha2,
        code: request.billing.code?.id,
        isSendBookingConfirmation: request.isSendBookingConfirmation,
        bookingConfirmationMethod: request.bookingConfirmationMethod,
        isRebook: request.isRebook,
        providers: request.providers,
        specialty: request.specialty,
        placeRef: request.placeRef,
        origin: request.origin,
        draftRef: request.draftRef,
        duplicatedFromRef: request.duplicatedFromRef,
        recurring: request.recurring,
      };

      const { data } = await createRequest(createRequestParams);

      requestStore.update(s => {
        s.request = data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
        s.componentsToRender = <Request />;
      });

      snackBar.pop({ content: data.message });
    } catch (error) {
      snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
    } finally {
      setIsLoading(false);
    }
  };

  const showSubScreen = subscreen => {
    requestStore.update(s => {
      s.componentsToRender = subscreen;
    });
  };

  const removeSymptom = (e, symptomToRemove) => {
    e.stopPropagation();
    const newRecords = cloneDeep(request.records);
    const record = newRecords.find(r => r.type === "symptoms");
    record.data.symptoms = record.data.symptoms.filter(s => s.title !== symptomToRemove.title);
    requestStore.update(s => {
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  const removeAddon = (e, idToRemove) => {
    e.stopPropagation();
    requestStore.update(s => {
      s.unsavedChanges.items = request.items.filter(item => item.item._id !== idToRemove);
      s.unsavedChanges.isDirty.items = true;
    });
  };

  const symptomTags =
    symptoms?.length && symptomRecord?.data?.symptoms.length ? (
      <div className="tagsContainer">
        {symptomRecord.data.symptoms.map((symptom, i) => {
          const title = symptom?.title || symptom;
          const warning =
            request.status !== "draft" &&
            request.origin !== "WavPay" &&
            !isSymptomCovered(symptom, request.type, request.specialty);

          return (
            <Tag className={`${warning ? "warning" : ""}`} key={i}>
              {title}
              {warning && <ExclamationCircle />}
              <X role="button" aria-label={`Remove Symptom ${title}`} onClick={e => removeSymptom(e, symptom)} />
            </Tag>
          );
        })}
      </div>
    ) : null;

  const updateDestinationItem = (_arg, i) => {
    const selectedDestinationItem = destinationItems[i];
    const updatedItems = request.items.filter(item => !item.item.tags.includes("destination"));
    updatedItems.push({ quantity: 1, item: selectedDestinationItem });
    requestStore.update(s => {
      s.unsavedChanges.items = updatedItems;
      s.unsavedChanges.schedule.destinationAddress = selectedDestinationItem.data.address || {};
    });
  };

  const handleAddressToPlace = () => {
    requestStore.update(s => {
      s.unsavedChanges.schedule.isPickup = !isPickup;
    });
  };

  const ambulanceAddresses = isAmbulanceDelivery ? (
    <div className="ambulanceAddresses">
      <SwapVertical className="swapVertical" onClick={handleAddressToPlace} />

      <div className={isPickup ? "placeToAddress" : "addressToPlace"}>
        <InputButton
          className="requestedAddress"
          icon={!isPickup ? bluedotIcon : locationIcon}
          text={getFormattedAddress(requestedAddress)}
          showInfo={error.requestedAddress}
          onClick={showAddressList}
          placeholder="Add Location"
          data-testid="addLocation"
          disabled={!payer._id}
        />

        <div className="destination">
          <Select
            icon={!isPickup ? locationIcon : bluedotIcon}
            placeholder="Place type"
            data-testid="selectPlaceType"
            className="destinationType"
            onChange={updateDestinationItem}
            defaultValue={destinationItem?._id}
            options={destinationItems.map(i => {
              const currentTime = format(
                isValidDate(request.schedule.start) ? new Date(request.schedule.start) : new Date(),
                "HH:mm",
              );
              const currentDestinationItemByHour = i.prices.find(item =>
                item.from && item.to ? currentTime >= item.from && currentTime <= item.to : item,
              );
              return {
                id: i._id,
                title: i.title,
                subtitle: i.description,
                info: {
                  text: currentDestinationItemByHour.price.base ? (
                    <Price
                      prefix={`${currentDestinationItemByHour.title || ""} +`}
                      value={currentDestinationItemByHour.price.base}
                    />
                  ) : (
                    <span>Standard</span>
                  ),
                },
              };
            })}
          />

          <InputButton
            className="destinationAddress"
            icon={locationList}
            text={destinationAddress?.line1}
            placeholder="Place address"
            data-testid="selectPlaceAddress"
            showInfo={error.destinationAddress}
            disabled={!destinationItem?.data?.placeType}
            onClick={() => showSubScreen(<DestinationList />)}
          />
        </div>
      </div>
    </div>
  ) : null;

  const [userList, setUserList] = useState([]);

  const fetchUsers = async search => {
    try {
      const { data } = await getPatientsFromUSMS({
        search,
        alpha2,
        "keys[]": "paymentMethods",
      });
      setUserList(data.users);
    } catch (error) {
      snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
    }
  };

  const onChangeUserIsPatient = userIsPatient => {
    if (userIsPatient) onChangePatient(payer);
    requestStore.update(s => {
      s.unsavedChanges.payerIsPatient = userIsPatient;
    });
  };

  const showProviderAssignment = () => {
    if (config.shouldDisableAssign) return;
    showSubScreen(<ProviderAssignment origin="create" />);
  };

  const addonItems = () => {
    const items = request.items.filter(item => item.item && containsAll(item.item.tags, ["addons"]));
    if (items.length)
      return items.map((item, i) => (
        <TagInfo
          key={i}
          title={item.item.title}
          subtitle={
            <>
              {item.item.description} <Price value={item.price?.base} />
            </>
          }
          action={<X onClick={e => removeAddon(e, item.item._id)} />}
        />
      ));
  };

  const onChangePayer = async userData => {
    try {
      const { data } = await getUser(userData._id, { alpha2 });
      const { preferredMethod } = getDefaultPaymentInfo(data.user);
      requestStore.update(s => {
        s.unsavedChanges.owner = data.user;
        s.unsavedChanges.user = data.user;
        s.unsavedChanges.payerIsPatient = true;
        s.unsavedChanges.business = null;
        s.unsavedChanges.isDirty.billing = true;
        s.unsavedChanges.isSendBookingConfirmation = false;
        s.unsavedChanges.bookingConfirmationMethod = "SMS";
        s.unsavedChanges.availablePaymentMethods = getAvailablePaymentMethods({
          country,
          user: data.user,
          owner: data.user,
        });
        s.unsavedChanges.billing.preferredMethod = preferredMethod?._id;
        if (isMedDelivery) {
          s.unsavedChanges.billing = {
            ...s.unsavedChanges.billing,
            ...freePaymentMethod,
          };
        } else {
          s.unsavedChanges.billing.selectedPaymentMethod = s.unsavedChanges.availablePaymentMethods?.find(
            pm => pm._id === s.unsavedChanges.billing.preferredMethod,
          );
        }
      });
      setError({ ...error, payer: false });
    } catch (error) {
      snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
    }
  };

  const onChangePatient = async userData => {
    try {
      const { data } = await getUser(userData._id, { alpha2 });
      const { preferredMethod } = getDefaultPaymentInfo(payer);
      requestStore.update(s => {
        s.unsavedChanges.user = data.user;
        s.unsavedChanges.payerIsPatient = data.user._id === payer._id;
        s.unsavedChanges.business = null;
        s.unsavedChanges.isDirty.user = true;
        s.unsavedChanges.availablePaymentMethods = getAvailablePaymentMethods({
          country,
          owner: request.owner,
          user: data.user,
        });
        s.unsavedChanges.billing.preferredMethod = preferredMethod._id;
        if (isMedDelivery) {
          s.unsavedChanges.billing = {
            ...s.unsavedChanges.billing,
            ...freePaymentMethod,
          };
        } else {
          s.unsavedChanges.billing.selectedPaymentMethod = s.unsavedChanges.availablePaymentMethods?.find(
            pm => pm._id === s.unsavedChanges.billing.preferredMethod,
          );
        }

        if (s.unsavedChanges.case.id) s.unsavedChanges.case = {};
      });
    } catch (error) {
      snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
    }
  };

  const getDefaultPaymentInfo = payerData => {
    return { preferredMethod: payerData.paymentMethods.find(pm => pm.isDefault) };
  };

  const getFirstPhoneNumber = (phoneNumbers, prefix, postfix) => {
    if (phoneNumbers && phoneNumbers.length > 0 && phoneNumbers[0].ext && phoneNumbers[0].number)
      return `${prefix}${phoneNumbers[0].ext} ${phoneNumbers[0].number}${postfix}`;
    else return "";
  };

  const onChangeClinicalNote = message => {
    requestStore.update(s => {
      const newRecords = request.records.filter(r => r.type !== "clinicalNotes");
      newRecords.push({ type: "clinicalNotes", data: { clinicalNotes: [{ message }] } });
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.clinicalNotes = true;
    });
  };

  const onChangePatientCondition = patientCondition => {
    requestStore.update(s => {
      const newRecords = cloneDeep(request.records);
      const record = newRecords.find(r => r.type === "symptoms");
      if (record) record.data.patientCondition = patientCondition;
      else
        newRecords.push({
          type: "symptoms",
          data: {
            patientCondition,
          },
        });
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  const onChangePatientNotes = notes => {
    requestStore.update(s => {
      const newRecords = cloneDeep(request.records);
      const record = newRecords.find(r => r.type === "symptoms");
      if (record) record.data.notes = notes;
      else
        newRecords.push({
          type: "symptoms",
          data: {
            notes,
          },
        });
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  const onClickEditPayer = useCallback(() => {
    showSubScreen(
      <CreateUser
        title="User Details"
        textAction="Update User"
        onSuccess={updatedUser => {
          requestStore.update(s => {
            s.unsavedChanges.owner = updatedUser;
            s.unsavedChanges.availablePaymentMethods = getAvailablePaymentMethods({
              country,
              owner: updatedUser,
              user: request.user,
            });
            if (s.unsavedChanges.owner._id === s.unsavedChanges.user?._id) s.unsavedChanges.user = updatedUser;
          });
        }}
        userData={payer}
        isOwner
      />,
    );
  }, [country, payer, request.user]);

  const onClickEditPatient = () =>
    showSubScreen(
      <CreateUser
        title="Patient Details"
        textAction="Update Patient"
        onSuccess={updatedUser => {
          requestStore.update(s => {
            s.unsavedChanges.user = updatedUser;
            s.unsavedChanges.availablePaymentMethods = getAvailablePaymentMethods({
              country,
              user: updatedUser,
              owner: request.owner,
            });
            if (s.unsavedChanges.user._id === s.unsavedChanges.owner?._id) s.unsavedChanges.owner = updatedUser;
          });
        }}
        userData={patient}
      />,
    );

  const onClickAddPayer = () =>
    showSubScreen(<CreateUser title="User Details" textAction="Create User" onSuccess={onChangePayer} isOwner />);

  const onClickAddPatient = () =>
    showSubScreen(<CreateUser title="Patient Details" textAction="Create Patient" onSuccess={onChangePatient} />);

  const handleClickRecurringAppointment = () => {
    showSubScreen(<RecurringAppointment />);
  };

  const handleClickNotification = () => {
    showSubScreen(<Notification />);
  };

  const onChangeProviderSpecialty = e => {
    const interval = getInterval(request.type);
    const startTime = roundToNearestMinutes(addMinutes(new Date(), Math.ceil(interval / 2)), { nearestTo: interval });
    const endTime = getDefaultEndTime(e.target.value, request.type, startTime);

    const changes = {
      specialty: e.target.value,
      schedule: {
        ...request.schedule,
        start: startTime,
        end: endTime,
        destinationAddress: isMedDelivery ? undefined : request.schedule.destinationAddress,
      },
      billing:
        e.target.value !== "Rider" || request.business ? request.billing : { ...request.billing, ...freePaymentMethod },
    };

    if (e.target.value !== "Nurse") changes.isIncludeVideo = false;

    updateRequest(changes);
  };

  const paymentMethodText = () => {
    if (request.billing.free) {
      return (
        <div className="freeInput">
          <span className="content">Free of Charge</span>

          <span className="warning">Patient will not be charged</span>
        </div>
      );
    }

    if (paymentMethod?.origin === "business") {
      return (
        <div className="corporatePaymentMethod">
          <Tag>{paymentMethod?.name}</Tag> {paymentMethod?.data?.name}
        </div>
      );
    }

    if (paymentMethod?.type === "other") {
      return (
        <>
          {paymentMethod?.name} <span>{request.billing.note}</span>
        </>
      );
    }

    return paymentMethod?.name || paymentMethod?.data?.name || "";
  };

  const providerList = request.providers?.map((p, i) => {
    const provider = p?.user || p;

    return (
      <div className="providerInfoItem" key={i}>
        <Avatar userData={provider} />

        <div className="providerDetail">
          <div className="name">
            <div className="fullName">{provider.name}</div>
            {p.owner && <div className="primaryInfo">Primary</div>}
          </div>

          <div className="type">{provider.specialty}</div>
        </div>

        <div className="seenStatus">
          <CheckGrey />
        </div>
      </div>
    );
  });

  const checkPaymentMethods = paymentMethods => {
    const cards = paymentMethods.filter(pm => pm.type === "card");

    const activeCard = cards.find(
      card => new Date() < parse(`${card.data.cardExpMonth}-${card.data.cardExpYear}`, "MM-yyyy", new Date()),
    );

    return { isCardAttached: Boolean(cards.length), isAtLeastOneActive: Boolean(activeCard) };
  };

  const generateCardIcon = paymentMethods => {
    const { isCardAttached, isAtLeastOneActive } = checkPaymentMethods(paymentMethods);
    return isCardAttached && <CreditCardRoundedIcon color={isAtLeastOneActive ? "icon" : "error"} />;
  };

  const onClickCreateAvixoUser =
    ({ jarvisRef }) =>
    async e => {
      e.preventDefault();

      try {
        const { data } = await createAvixoUser(jarvisRef, alpha2);
        if (request.owner?._id === jarvisRef) onChangePayer(data.user);
        if (request.user?._id === jarvisRef) onChangePatient(data.user);
      } catch (error) {
        snackBar.pop({ content: error.data, alertProps: { severity: "error" } });
      }
    };

  const onSelectOrigin = event => {
    updateRequest({ origin: event.target.value });
  };

  const onChangeBusiness = e => {
    const selectedBusiness = patient.businesses.find(b => b.ref?.id === e.target.value)?.ref;

    requestStore.update(s => {
      s.unsavedChanges.business = selectedBusiness;
      s.unsavedChanges.isDirty.business = true;

      if (request.case.business) s.unsavedChanges.case = {};
    });
  };

  const onChangeIsIncludeVideo = () => {
    requestStore.update(s => {
      s.unsavedChanges.isIncludeVideo = !request.isIncludeVideo;
    });
  };

  const renderEstimatedPrice = () => {
    if (isMedDelivery) return null;
    const caption = (
      <div className="caption">
        {request.items.length === 1
          ? "Basic Service Fee. Not inclusive of additional services, specialist medicine."
          : "Inclusive of additional requirements"}
        <br />
        Final Price will be reflected after request has been completed.
      </div>
    );

    if (free)
      return (
        <>
          <span className="price">Free</span>
          {caption}
        </>
      );
    else
      return (
        <>
          <Price value={request.billing?.totals?.grand} postfix="++" />
          {caption}
        </>
      );
  };

  if (isLoading) return <LoadingSection className="modalContent">Loading request data</LoadingSection>;

  return (
    <div className="modalContent createRequestUM">
      <Header>
        <div className="title">
          {headerText}
          {["Provider App", "Patient App", "WavPay"].includes(request.origin) ? (
            <Typography variant="caption" color="purple.500" ml={1} mt={0.25}>
              {request.origin}
            </Typography>
          ) : (
            <MuiSelect
              value={request.origin ?? ""}
              onChange={onSelectOrigin}
              className={clsx({ empty: !request.origin, error: error.origin })}
              variant="standard"
              size="small"
              data-testid="selectOrigin"
              IconComponent={ExpandMoreIcon}
              renderValue={selected =>
                selected === "" ? (
                  <Grid container alignItems="center">
                    {error.origin ? (
                      <Box mt={0.5}>
                        <Tooltip text="Required Field" />
                      </Box>
                    ) : (
                      <PanoramaFishEyeIcon sx={{ height: "16px", width: "16px" }} />
                    )}
                    <Typography variant="caption" ml={0.75}>
                      Select source
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container alignItems="center">
                    {origins[selected].icon}
                    <Typography variant="caption" ml={0.75}>
                      {selected}
                    </Typography>
                  </Grid>
                )
              }
              sx={{
                minHeight: "unset",
                height: "26px",
                marginLeft: 2,
                color: "purple.500",
                backgroundColor: "purple.50",
                borderRadius: "6px",
                ".MuiSelect-select": {
                  paddingY: 0.75,
                  paddingX: 1,
                },
                ".MuiSelect-select:focus": {
                  backgroundColor: "purple.50",
                  borderRadius: "6px",
                  paddingY: 0.25,
                  paddingLeft: 1,
                },
                "&.empty": {
                  backgroundColor: "jarvisDivider.primary",
                  color: "text.secondary",
                  ".MuiSelect-select:focus": {
                    backgroundColor: "jarvisDivider.primary",
                  },
                  ".MuiSelect-icon": {
                    color: "text.secondary",
                  },
                  "&.error": {
                    backgroundColor: "componentErrorRed50.main",
                    ".MuiSelect-select:focus": {
                      backgroundColor: "componentErrorRed50.main",
                    },
                  },
                },
                ".MuiSelect-icon": {
                  marginRight: 0.5,
                  top: "calc(50% - 0.4em)",
                  color: "purple.500",
                  width: "20px",
                  height: "20px",
                },
              }}
              displayEmpty
              disableUnderline>
              <MenuItem disabled value="" key="">
                <Typography variant="caption" ml={0.75}>
                  Select source
                </Typography>
              </MenuItem>

              {Object.keys(origins).map(origin => (
                <MenuItem data-testid={`select-${origin}`} key={origin} value={origin}>
                  <Typography variant="caption" ml={0.75}>
                    {origin}
                  </Typography>
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        </div>

        {isDraft && (
          <StatusTag className={`${request.status}`} statusName={`${request.status}`}>
            {serviceStatus[request.status]}
          </StatusTag>
        )}

        <X role="button" aria-label="close" className="close" onClick={onClickClose} />
      </Header>

      <div className="content">
        <AutoComplete
          className="payer highlighted"
          autoFocus
          icon={personIcon}
          defaultValue={payer?.name}
          onSelect={i => onChangePayer(userList[i])}
          list={userList.map(({ name, phoneNumbers = [], email, paymentMethods, platos }) => ({
            title: name,
            subtitle: `${getFirstPhoneNumber(phoneNumbers, "", " | ")}${email.address}`,
            icon: generateCardIcon(paymentMethods),
            info: platos?.length ? { text: "Avixo" } : undefined,
          }))}
          subscreenText="New User"
          disabled={request.payerDisabled ?? false}
          onClick={onClickAddPayer}
          fetch={fetchUsers}
          onDelete={() => {
            requestStore.update(s => {
              s.unsavedChanges.owner = { paymentMethods: [], phoneNumbers: [], relatives: [] };
              s.unsavedChanges.user = { paymentMethods: [], phoneNumbers: [], relatives: [] };
              s.unsavedChanges.payerIsPatient = false;
            });
          }}
          showInfo={error.payer}
          editInfo={
            payer._id && (
              <div className="inputEdit">
                {payer.platos.length ? (
                  <a
                    href={`${process.env.REACT_APP_AVIXO_URL}${alpha2.toLowerCase()}/patient/default/view?id=${
                      payer.platos[0].id
                    }`}
                    target="_blank"
                    rel="noreferrer">
                    Open in Avixo
                  </a>
                ) : (
                  <a href="/" onClick={onClickCreateAvixoUser({ jarvisRef: payer._id })}>
                    Create in Avixo
                  </a>
                )}

                <Link aria-label="edit payer" to="#" onClick={onClickEditPayer}>
                  Edit
                </Link>
              </div>
            )
          }
          placeholder="Add user"
          data-testid="addUser"
        />

        <Checkbox
          text="The User is The Patient"
          onChange={() => onChangeUserIsPatient(!request.payerIsPatient)}
          className="autocompleteMargin"
          disabled={request.payerDisabled ?? false}
          defaultChecked={request.payerIsPatient}
        />

        <InputButton
          className="paymentMethod"
          icon={getPaymentIcon(paymentMethod, request)}
          text={paymentMethodText()}
          showInfo={error.preferredMethod}
          placeholder="Select a payment method"
          data-testid="selectPaymentMethod"
          disabled={!payer?._id}
          onClick={() => showSubScreen(<PaymentMethod />)}
        />

        <Grid container px={2} gap={2}>
          <Box display="flex" height={30} alignItems="center">
            <Building />
          </Box>

          <Grid item xs alignSelf="center">
            <FormControl fullWidth>
              <MuiSelect
                value={request.business?._id || ""}
                onChange={onChangeBusiness}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  elevation: 2,
                  sx: {
                    ".MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "transparent",
                    },
                  },
                  PaperProps: {
                    sx: { mt: 0.5, maxHeight: 280 },
                  },
                }}
                input={
                  <OutlinedInput
                    size="small"
                    color="secondary"
                    sx={{
                      height: "30px",
                      minHeight: "unset",
                      fontSize: "12px",
                      ".MuiOutlinedInput-input": { paddingLeft: "10px" },
                    }}
                    fullWidth
                  />
                }
                disabled={!patient.businesses?.length}
                displayEmpty>
                <MenuItem key="" value="" sx={{ fontSize: "12px" }}>
                  <em>- No Corporate -</em>
                </MenuItem>

                {patient.businesses?.map(b => (
                  <MenuItem key={b.ref?._id} value={b.ref?._id} sx={{ fontSize: "12px" }}>
                    {b.name} [{b.ref?.tag}]
                  </MenuItem>
                ))}
              </MuiSelect>

              <FormHelperText sx={{ ml: "10px" }}>Select if appointment is to be tagged to a corporate</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Tabs tabs={filteredAvailableServices} activeIndex={activeTabIndex} onTabClick={confirmServiceTypeChange} />

        {error.alert && (
          <Alert
            className={`${error.alert.type}`}
            text={`${error.alert.text}`}
            icon={<Tooltip text="Required Field" />}
            button={
              error.alert.action ? (
                <Link
                  to="#"
                  type="button"
                  className="linkGreen"
                  onClick={error.alert.action.onClick}>{`${error.alert.action.text}`}</Link>
              ) : null
            }
          />
        )}

        <Grid container justifyContent="space-between" alignItems="center">
          <MuiSelect
            sx={{ fontSize: "12px", padding: "15px", color: "#126ef8" }}
            className="specialtySelect"
            data-testid="selectProviderSpecialty"
            value={request.specialty}
            label="Provider Type"
            onChange={onChangeProviderSpecialty}
            disabled={!!request.providers}>
            {providersAvailable.map(option =>
              ["Visit", "Clinic", "Video"].includes(request.type)
                ? !["Ambulance", "Rider"].includes(option.value) && (
                    <MenuItem sx={{ fontSize: "12px" }} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                : ["Ambulance", "Rider"].includes(option.value) && (
                    <MenuItem sx={{ fontSize: "12px" }} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
            )}
          </MuiSelect>
          {request.specialty === "Nurse" && request.type === "Visit" && (
            <Checkbox
              text="Include video consult in this visit"
              onChange={onChangeIsIncludeVideo}
              defaultChecked={request.isIncludeVideo}
              style={{ margin: "unset", paddingRight: "16px" }}
            />
          )}
        </Grid>

        {isMedDelivery ? (
          <MedDeliveryForm
            onChangeSchedule={onChangeSchedule}
            error={error}
            showSubScreen={showSubScreen}
            onChangePatient={onChangePatient}
            setError={setError}
            onClickCreateAvixoUser={onClickCreateAvixoUser}
            onClickEditPatient={onClickEditPatient}
          />
        ) : (
          <>
            {isClinic && (
              <InputButton
                className="requestedAddress"
                icon={<HospitalIcon className="clinicIcon" />}
                placeholder="Choose Clinic"
                data-testid="chooseClinic"
                text={getFormattedAddress(requestedAddress)}
                showInfo={error.requestedAddress}
                onClick={showClinicList}
              />
            )}

            <div className="scheduleStart">
              <Schedule onChange={onChangeSchedule} showInfo={error.schedule} />
            </div>

            {recommendedProvider &&
              (recommendedProvider.count ? (
                <Grid ml={6.5} mb={-1}>
                  <Typography variant="caption" color="secondary.main">
                    {recommendedProvider.count} recommended providers available at this timeslot
                  </Typography>
                </Grid>
              ) : (
                <Grid ml={6.5} mb={-1}>
                  <Typography variant="caption" color="warningOrange.500">
                    No recommended provider available at this timeslot
                  </Typography>
                </Grid>
              ))}
            {isAmbulanceDelivery ? (
              ambulanceAddresses
            ) : !isClinic ? (
              <InputButton
                className="requestedAddress"
                icon={locationIcon}
                placeholder="Add Location"
                data-testid="addLocation"
                text={getFormattedAddress(requestedAddress)}
                showInfo={error.requestedAddress}
                onClick={showAddressList}
                disabled={!payer?._id}
              />
            ) : null}
            {config.showProviderSection && (
              <div className="provider">
                <div className="providerGroup">
                  <Provider2 />
                  {request.providers?.length > 0 ? (
                    <div
                      className={clsx("providerInfo", { disabled: config.shouldDisableAssign })}
                      onClick={showProviderAssignment}>
                      {providerList}
                    </div>
                  ) : (
                    <MUIButton
                      variant="contained"
                      color="secondary"
                      onClick={showProviderAssignment}
                      disabled={!requestedAddress?.line1 && !destinationAddress?.line1}
                      sx={{ ml: -1, fontWeight: 600, "&.Mui-disabled": { color: "white" } }}>
                      Assign provider
                    </MUIButton>
                  )}
                </div>
              </div>
            )}

            <hr className="addressSeparator" />

            <div className="patientContainer">
              <AutoComplete
                className="patient"
                icon={patientIcon}
                defaultValue={patient.name}
                disabled={!payer?._id || request.payerDisabled}
                onSelect={i => onChangePatient(payer?.relatives[i])}
                list={payer?.relatives?.map(({ name }) => ({ title: name }))}
                subscreenText="New Patient"
                openOnClick
                onClick={onClickAddPatient}
                onDisabledClick={!request.payerDisabled && (() => setError({ ...error, payer: true }))}
                onDelete={() => {
                  requestStore.update(s => {
                    s.unsavedChanges.user = { paymentMethods: [], phoneNumbers: [], relatives: [] };
                    s.unsavedChanges.case = {};
                    s.unsavedChanges.payerIsPatient = false;
                  });
                }}
                placeholder="Add patient"
                data-testid="addPatient"
                showInfo={error.patient}
                editInfo={
                  patient._id && (
                    <div className="inputEdit">
                      {patient.platos.length ? (
                        <a
                          href={`${process.env.REACT_APP_AVIXO_URL}${alpha2.toLowerCase()}/patient/default/view?id=${
                            patient.platos[0].id
                          }`}
                          target="_blank"
                          rel="noreferrer">
                          Open in Avixo
                        </a>
                      ) : (
                        <a href="/" onClick={onClickCreateAvixoUser({ jarvisRef: patient._id })}>
                          Create in Avixo
                        </a>
                      )}
                      <Link to="#" onClick={onClickEditPatient}>
                        Edit
                      </Link>
                    </div>
                  )
                }
              />
            </div>

            <InputButton
              className="case"
              icon={caseIcon}
              text={
                !!request.case.type && (
                  <>
                    {request.case.type} {programTitle} {request.case.package?.name} {caseBusinessTag} {caseId}
                  </>
                )
              }
              showInfo={error.case || request.business}
              info={
                patient.name &&
                request.business && (
                  <div className="coveredTag">
                    <BlueShield /> COVERED
                  </div>
                )
              }
              placeholder="Select case"
              data-testid="selectCase"
              disabled={request.caseDisabled || !patient._id}
              onClick={() => showSubScreen(<CaseList />)}
            />

            {!isAmbulanceDelivery && (
              <InputButton
                className="symptom"
                icon={symptomIcon}
                text={symptomTags}
                showInfo={error.symptoms}
                placeholder="Add symptoms"
                data-testid="selectSymptoms"
                onClick={() => showSubScreen(<SymptomList />)}
              />
            )}

            {isAmbulanceDelivery && (
              <Input
                className="patientCondition"
                icon={heartbeatIcon}
                value={symptomRecord?.data.patientCondition}
                placeholder="Patient Condition"
                data-testid="enterPatientCondition"
                info={<Tooltip id="tooltip" text="Required field" />}
                showInfo={error.patientCondition}
                isMultiLine
                noBorder
                onChange={onChangePatientCondition}
              />
            )}

            <InputButton
              className="addonItems"
              icon={fileAddIcon}
              placeholder="Additional Requirements"
              data-testid="selectAddonItems"
              onClick={() => showSubScreen(<AddonList />)}
              text={addonItems()}
            />

            <Input
              className="notes"
              icon={patientNotes}
              noBorder
              isMultiLine
              value={symptomRecord?.data.notes}
              placeholder="Add patient notes (shown to patient and provider)"
              data-testid="enterPatientNotes"
              onChange={onChangePatientNotes}
            />

            <Input
              className="notes"
              icon={notesIcon}
              noBorder
              value={clinicalNote}
              placeholder="Add clinical notes (shown to provider only)"
              data-testid="enterClinicalNotes"
              isMultiLine
              counter
              maxLength={4000}
              onChange={onChangeClinicalNote}
            />
          </>
        )}

        {payments?.length > 0 && (
          <Box px={2} mt={2}>
            {payments?.map(p => (
              <PaymentHistory key={p.id} payment={p} />
            ))}
          </Box>
        )}
      </div>

      <Footer>
        <hr />

        <div className="footerContainer">
          {renderEstimatedPrice()}

          <Grid container justifyContent="space-between" alignItems="center">
            <LoadingButton
              color="secondary"
              onClick={onClickSaveChanges}
              sx={{ fontWeight: 600, visibility: isDraft ? "visible" : "hidden" }}
              loading={isSavingDraft}
              disabled={!isFormDirty}>
              Save Changes
            </LoadingButton>

            <Grid item xs="auto" container gap={2}>
              <Grid item xs="auto" container gap={1}>
                {payer._id ? (
                  <>
                    <Tooltip
                      icon={
                        <div
                          role="button"
                          aria-label="see recurring appointments"
                          className="total"
                          onClick={handleClickRecurringAppointment}>
                          <EventRepeat color="secondary" />
                          {request.recurring?.numberOfAppointments && (
                            <span className="text">{request.recurring.numberOfAppointments} Appointments</span>
                          )}
                        </div>
                      }
                      dataPlace="bottom"
                      text="Recurring Appointments"
                    />
                    <Tooltip
                      icon={
                        <div
                          role="button"
                          aria-label="see notifications"
                          className="total"
                          onClick={handleClickNotification}>
                          <NotificationEnable />
                          {request.isSendBookingConfirmation && <span className="text">1 Notification</span>}
                        </div>
                      }
                      dataPlace="bottom"
                      text="See Notifications"
                    />
                  </>
                ) : (
                  <>
                    <Tooltip
                      icon={
                        <Grid container width={34} height={34} alignItems="center" justifyContent="center">
                          <EventRepeat sx={{ color: "#dadada" }} />
                        </Grid>
                      }
                      dataPlace="bottom"
                      text={
                        <>
                          Recurring Appointments are disabled
                          <br />
                          until you have added a user
                        </>
                      }
                    />
                    <Tooltip
                      icon={
                        <Grid container width={34} height={34} alignItems="center" justifyContent="center">
                          <NotificationDisable />
                        </Grid>
                      }
                      dataPlace="bottom"
                      text={
                        <>
                          Notifications are disabled
                          <br />
                          until you have added a user
                        </>
                      }
                    />
                  </>
                )}
              </Grid>

              <Button text={buttonText} type="button" className="primary" disabled={isLoading} onClick={onClickBook} />
            </Grid>
          </Grid>
        </div>
      </Footer>

      {showUnsavedChangesDialog && (
        <ConfirmationDialog
          title="Unsaved Changes"
          confirmText="Save Changes"
          cancelText="Discard Changes"
          onConfirm={onConfirmSaveChanges}
          onCancel={onCancelSaveChanges}
          onClose={onCloseSaveChangesDialog}
          showCancelButton>
          You have made changes to the current appointment. Are you sure you want to discard the changes?
        </ConfirmationDialog>
      )}
    </div>
  );
}

const useRecommendedProvider = ({ type, specialty, scheduleStart, scheduleEnd }) => {
  const alpha2 = store.useState(s => s.country.alpha2);
  const [result, setResult] = useState(false);

  useEffect(() => {
    let isActive = true;

    if (!type || !specialty || !scheduleStart || !scheduleEnd || !alpha2) return;

    const fetchRecommendedProviders = async () => {
      try {
        const { data } = await getRecommendedProviders({ type, specialty, scheduleStart, scheduleEnd, alpha2 });

        if (isActive) {
          setResult(data.result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchRecommendedProviders();

    return () => {
      isActive = false;
    };
  }, [type, specialty, scheduleStart, scheduleEnd, alpha2]);

  return result;
};
