import { Grid, Link as MUILink, Typography } from "@mui/material";
import { format } from "date-fns";
import { isValidDate } from "utils/globalUtils";
import PaymentStatusLabel from "./PaymentStatusLabel";

interface PaymentHistoryProps {
  payment: {
    source: string;
    refunds: {
      type: string;
      eventDate: string;
      paidAmount: number;
    }[];
    sourceId: string;
    remark: string;
    status: string;
    eventDate: string;
    amount: number;
    paidAmount: number;
    isPreAuthorization: boolean;
  };
}

export default function PaymentHistory({ payment }: Readonly<PaymentHistoryProps>) {
  const preAuthReversal = payment.refunds.find(r => r.type === "reversal");
  const refunds = payment.refunds.filter(r => r.type === "refund");
  const isOpenStripeInvoice =
    payment.source === "stripe" && payment.remark.includes("Stripe Invoice") && payment.status === "initialized";
  const amountTextStyles = {
    variant: "caption" as const,
    color: "text.secondary",
    align: "right" as const,
    width: 50,
  };

  return (
    <>
      <Grid container mt={0.5} justifyContent="space-between">
        <Grid item xs>
          {payment.source === "stripe" && (
            <MUILink
              href={`${process.env.REACT_APP_STRIPE_PAYMENT_URI}/${payment.sourceId}`}
              target="_blank"
              color="wildTide.500"
              fontWeight={600}>
              {payment.remark}{" "}
              {payment.eventDate && `- on ${format(new Date(payment.eventDate), "dd MMM yyyy, HH.mm")}`}
            </MUILink>
          )}

          {payment.source === "avixo" && (
            <Typography variant="caption" color="text.secondary">
              {payment.remark}
            </Typography>
          )}
        </Grid>

        <Grid container item xs="auto">
          <Grid mr={1}>
            <PaymentStatusLabel paymentStatus={payment.status} isPreAuthorized={payment.isPreAuthorization} />
          </Grid>

          <Typography {...amountTextStyles}>
            {payment.isPreAuthorization || refunds?.length || isOpenStripeInvoice
              ? Number(payment.amount).toLocaleString()
              : Number(payment.paidAmount).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      {payment.isPreAuthorization && payment.status === "succeeded" && preAuthReversal && (
        <Grid container mt={0.5} justifyContent="end">
          <Grid mr={1}>
            <PaymentStatusLabel paymentStatus="cancelled" isPreAuthorized={payment.isPreAuthorization} />
          </Grid>

          <Typography {...amountTextStyles}>-{Number(preAuthReversal.paidAmount).toLocaleString()}</Typography>
        </Grid>
      )}

      {refunds?.map((r, i) => (
        <Grid container key={`${payment.sourceId}.refund.${i}`} mt={0.5} pl={2} justifyContent="space-between">
          <Typography variant="caption" color="text.secondary">
            Refunded on {(isValidDate(r.eventDate) && format(new Date(r.eventDate), "dd MMM yyyy, HH.mm")) || "-"}
          </Typography>

          <Typography {...amountTextStyles}>-{Number(r.paidAmount).toLocaleString()}</Typography>
        </Grid>
      ))}
    </>
  );
}
